<template>
  <div>
    <CCard class="p-4">
      <CRow>
        <CCol lg="6">
          <h5>Personnel Ledger - Cash Advance / Charges ({{dataList.total}})</h5>
        </CCol>
        <CCol lg="6">
          <CButton
            v-if="config.getPermission('personnel_ledger').create"
            size="sm"
            style="margin-right: 10px"
            color="success"
            @click="addModal()"
            class="float-lg-right"
            ><i class="fa fa-plus"></i>Add Personnel
          </CButton>
        </CCol>
      </CRow>

      <CRow class="mt-2">
        <CCol lg="2">
          <div class="form-group">
            <v-select
              v-model="entry_type"
              label="type"
              :options="[
                {
                  type: 'Cash Advance',
                  id: 'cash_advance',
                },
                {
                  type: 'Charges',
                  id: 'charges',
                },
                {
                  type: 'SSS Loan',
                  id: 'sss_loan',
                },
                {
                  type: 'Pagibig Loan',
                  id: 'pagibig_loan',
                },
                {
                  type: 'Other Deductions',
                  id: 'other_deductions',
                },
                
              ]"
              :reduce="(item) => item.id"
              placeholder="Type"
            >
            </v-select>
          </div>
        </CCol>
        <CCol lg="2">
          <div class="form-inline">
            <Datepicker
              @selected="changeStartDate"
              v-model="date_start"
              input-class="form-control bg-white mr-2"
              placeholder="Start Date"
              :disabled-dates="disabledDatesStart"
            >
            </Datepicker>
          </div>
        </CCol>
        <CCol lg="2">
          <Datepicker
            @selected="changeEndDate"
            v-model="date_end"
            input-class="form-control bg-white"
            placeholder="End Date"
            :disabled-dates="disabledDatesEnd"
          >
          </Datepicker>
        </CCol>

        <!-- <CCol lg="1">
          <div style="text-align: left">
            <CButton size="sm" color="info" @click="">
              <i class="fa fa-filter"></i>
            </CButton>
          </div>
        </CCol> -->
        <CCol lg="4">
          <div style="text-align: left">
            <CButton
              v-if="config.getPermission('personnel_ledger').download"
              size="sm"
              style="margin-right: 80px"
              color="btn btn-outline-primary"
              @click="download"
            >
              <i class="fa fa-download"></i> Download
            </CButton>
          </div>
        </CCol>
      </CRow>

      <hr />

      <CRow>
        <CCol lg="12">
          <div class="table-responsive-sm table-responsive-md">
            <CDataTable
              :tableFilter="{ placeholder: 'Search here...' }"
              pagination
              :itemsPerPage="10"
              itemsPerPageSelect
              :loading="isLoading"
              :items="personnel_Data"
              :fields="fields"
              hover
              outlined
            >
              <template #status="{ item }">
                <td
                  style="padding: 5px; width: 100px; text-transform: uppercase"
                >
                  <CBadge
                    style="margin-top: 10px"
                    :color="getBadge(item.status)"
                    >{{ item.status }}</CBadge
                  >
                </td>
              </template>
              <template #action="{ item }">
                <td style="padding: 5px; width: 100px">
                  <CDropdown toggler-text="Select" size="sm" color="info">
                    <CDropdownItem
                      v-if="config.getPermission('personnel_ledger').view"
                      @click="viewModal(item)"
                      ><i class="fa fa-eye">View</i></CDropdownItem
                    >
                    <!-- <CDropdownItem v-if="config.getPermission('personnel_ledger').update && item.status!='done'" @click="updateModal(item)"><i class="fa fa-pencil-square-o" > Update </i></CDropdownItem>    -->
                    <!-- <CDropdownItem
                      v-if="config.getPermission('personnel_ledger').delete"
                      @click="deleteItem(item)"
                      ><i class="fa fa-trash"> Delete</i>
                      </CDropdownItem 
                    >-->
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
            <!-- <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(personnel_Data.length / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />  -->
          </div>
        </CCol>
      </CRow>
    </CCard>
    <!-- VIEW -->
    <CModal
      :title="title"
      :show.sync="formModal"
      @close="closeModal()"
      color="info"
      size="md"
    >
      <form @submit.prevent="submit">
        <CRow>
          <CCol lg="12">
            <div class="form-group">
              <label style="font-size: 12px"
                >Crew (Personnel Name)
                <span v-if="!is_disabled" class="text-danger">* </span></label
              >
              <v-select
                label="name"
                :options="personnelList.data"
                :reduce="(item) => item.id"
                v-model="adjustmentParam.personnel_id"
                placeholder="Select.."
              >
              </v-select>
            </div>
          </CCol>
          <!-- <CCol lg="12">
            <label style="font-size: 12px">Type</label>
            <CInput readonly :value="adjustmentParam.plate_no" type="text" />
          </CCol> -->
        </CRow>

        <div slot="footer" class="w-100 mt-3">
          <!-- to avoid enabling button via inspect element -->
          <template v-if="!is_disabled">
            <CButton
              size="sm"
              type="submit"
              style="border-radius: 0.2rem; color: white"
              color="info"
              class="ml-1 mr-1 float-right"
            >
              Next
            </CButton>
            <!-- <CButton size="sm" v-else type="button" style="border-radius: .2rem; color: white;" disabled color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton> -->
          </template>
          <CButton
            size="sm"
            style="border-radius: 0.2rem; color: white"
            color="danger"
            class="ml-1 mr-1 float-right"
            @click="formModal = false"
          >
            Close
          </CButton>
        </div>
      </form>
      <div slot="footer" class="w-100"></div>
    </CModal>
  </div>
</template>
<script>
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
// import Search from './search'
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  mounted() {
    console.log(moment("10/09/2022").day());
    console.log(moment().add(27, "d").format("MM/DD/YYYY"), " as date");

    this.getData();
    this.getPersonnel();
  },
  filters: {
    formatDate(val) {
      if (val) return moment.unix(val).format("MMMM DD, YYYY");
      return "";
    },
    uppercase(val) {
      if (val) return val.toUpperCase();
      return "";
    },
  },
  watch: {},
  computed: {
    is_disabled() {
      return !this.adjustmentParam.personnel_id;
    },
  },
  data() {
    return {
      limit: 10,
      title: "",
      date_start: null,
      date_end: null,
      isLoading: false,
      config,
      dataParams: {},

      formModal: false,
      // showViewModal: false,
      dataList: {total:0},
      depotName: "",
      fields: [
        {
          key: "employee_name",
          label: "Name",
        },
        {
          key: "personnel_type",
          label: "Type",
        },
        {
          key: "personnel_ledger_CB",
          label: "Charges Balance",
        },
        {
          key: "personnel_ledger_CAB",
          label: "Cash Advance Balance",
        },
        {
          key: "personnel_ledger_SSSBalance",
          label: "SSS Loan Balance",
        },
        {
          key: "personnel_ledger_PagibigBalance",
          label: "Pagibig Loan Balance",
        },
        {
          key: "personnel_ledger_OtherDeductionsBalance",
          label: "Other Deductions Balance",
        },
        {
          key: "personnel_ledger_Total",
          label: "Total Balance",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      currentPage: 1,
      editMode: false,
      selectedRow: {},
      showCollapse: false,

      filter: {
        adjustment_id: "",
        booking_no: "",
        plate_no: "",
        destination: "",
        personnel_id: "",
        delivery_date_from: "",
        delivery_date_to: "",
        status: "",
      },

      personnelList: {
        data: [],        
      },

      personnel_Data: [
        // {
        // 	employee_id: 'ADM-00000001',
        // 	employee_name: 'April Ramiso',
        // 	personnel_type: 'Driver',
        // 	personnel_ledger_CB: '52.00',
        // 	personnel_ledger_CAB: '60.00',
        // 	personnel_ledger_Total: '112.00',
        // },
        // {
        // 	employee_id: 'ADM-00000002',
        // 	employee_name: 'Rhandel Solano',
        // 	personnel_type: 'Driver',
        // 	personnel_ledger_CB: '52.00',
        // 	personnel_ledger_CAB: '60.00',
        // 	personnel_ledger_Total: '112.00',
        // },
        // {
        // 	employee_id: 'ADM-00000003',
        // 	employee_name: 'Larah Ramiso',
        // 	personnel_type: 'Driver',
        // 	personnel_ledger_CB: '52.00',
        // 	personnel_ledger_CAB: '60.00',
        // 	personnel_ledger_Total: '112.00',
        // },
      ],

      showViewModal: false,

      adjustmentParam: {
        personnel_id: null,
      },

      mode: {
        view: false,
        new: true,
        edit: false,
      },
      entry_type: null,
      disabledDatesStart : {
        days : [0,2,3,4,5,6] //disable days except monday
      },
      disabledDatesEnd : {
        days : [1,2,3,4,5,6] // disable days except sunday
      },
    };
  },
  name: "Tables",
  components: { Datepicker, vSelect },
  methods: {
    changeStartDate(ev) {
      if (moment(ev).day() != 1) {
        alert("Start date should fall on Monday!");
        this.date_start = "";
      } else {
        this.date_end = moment(ev).add(27, "d").format("YYYY-MM-DD");
        this.date_start = ev;
      }
    },
    changeEndDate(ev) {
      if (moment(ev).day() > 0) {
        alert("End date should fall on Sunday!");
      } else this.date_end = ev;
    },
    download() {
      // this.getData('download');
      if (
        this.entry_type == null ||
        this.date_start == null ||
        this.date_end == null
      ) {
        Swal.fire({
          title: "Warning",
          text: "Please fill out the Type , Start & End Date fields.",
          icon: "warning",
        });
      } else {
        this.$showLoading(true)
        axios
          .get(`${config.api_path}/ledger/download`, {
            params: {
              start_date: moment(this.date_start).format("YYYY-MM-DD"), // '2021-09-06',
              end_date: moment(this.date_end).format("YYYY-MM-DD"), // '2022-10-02'
              entry_type: this.entry_type,
            },
          })
          .then((response) => {
            this.$showLoading(false)
            window.open(
              config.main_path.replace("/index.php", "") +
                "/" +
                response.data.file
            );

            setTimeout(() => {
              axios.get(`${config.api_path}/remove-file`, {
                params: {
                  filename: response.data.file,
                },
              });
            }, 2000);
          }).catch(err => {
				this.$showLoading(false)
			})
      }
    },

    submit() {
      console.log(this.adjustmentParam.personnel_id);
      window.open(
        `#/main/view_personnel_ledger/${this.adjustmentParam.personnel_id}`,
        "_blank"
      );
    },

    addModal() {
      this.setMode("new");
      this.formModal = !this.formModal;
      this.title = "Add Personnel";
      this.resetParam();
    },

    setMode(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
    viewModal(item) {
      window.open(
        `#/main/view_personnel_ledger/${item.personnel_id}`,
        "_blank"
      );
    },

    closeModal() {
      console.log("oyet");
    },
    reloadData() {
      this.getData();
    },
    // updatePage(data){
    // 	this.currentPage = data;
    // 	this.getData();
    // },

    search(event) {
      this.filter = event;
      this.getData();
    },

    getData() {
      this.isLoading = true;

      axios
        .get(config.api_path + "/ledger/list/info", {
          params: {
            // page : this.currentPage,
            // depot_id : this.filter.depot_id,
            // limit : 20,
            // company_id : this.user_details.company_id
          },
        })
        .then((response) => {
          this.personnel_Data = response.data.data.map(item => {
					item.personnel_ledger_CB = item.personnel_ledger_CB ? this.$options.filters.number_format(item.personnel_ledger_CB, 'currency', 2) : '';
					item.personnel_ledger_CAB = item.personnel_ledger_CAB ? this.$options.filters.number_format(item.personnel_ledger_CAB, 'currency', 2) : '';
          item.personnel_ledger_SSSBalance = item.personnel_ledger_SSSBalance ? this.$options.filters.number_format(item.personnel_ledger_SSSBalance, 'currency', 2) : '';
          item.personnel_ledger_PagibigBalance = item.personnel_ledger_PagibigBalance ? this.$options.filters.number_format(item.personnel_ledger_PagibigBalance, 'currency', 2) : '';
          item.personnel_ledger_OtherDeductionsBalance = item.personnel_ledger_OtherDeductionsBalance ? this.$options.filters.number_format(item.personnel_ledger_OtherDeductionsBalance, 'currency', 2) : '';
          item.personnel_ledger_Total = item.personnel_ledger_Total ? this.$options.filters.number_format(item.personnel_ledger_Total, 'currency', 2) : '';
					return item;
				 });
         this.dataList.total = response.data.total;
          // this.isLoading = false;
          // this.personnel_Data = response.data.data;
          // this.dataList.total = response.data.total;
        })
        .catch((err) => {
          console.error(err);

          this.isLoading = false;
        })
        .finally((fin) => {
          console.log(fin);
          this.isLoading = false;
        });
    },

    getPersonnel() {
      this.isLoading = true;
      axios
        .get(config.api_path + "/reference/personnel-list")
        .then((response) => {
          this.personnelList = response.data;
        });
    },
    // deleteItem(item) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Are you sure you want to delete this adjustment",
    //     showCancelButton: true,
    //     confirmButtonText: `Yes`,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       axios
    //         .delete(config.api_path + `/adjustment/${item.id}`)
    //         .then((response) => {
    //           this.getData();
    //           Swal.fire({
    //             title: "Success!",
    //             text: "Adjustment successfully deleted",
    //             icon: "success",
    //           });
    //         });
    //     }
    //   });
    // },
  },
};
</script>
