var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v("Personnel Ledger - Cash Advance / Charges ("+_vm._s(_vm.dataList.total)+")")])]),_c('CCol',{attrs:{"lg":"6"}},[(_vm.config.getPermission('personnel_ledger').create)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"success"},on:{"click":function($event){return _vm.addModal()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("Add Personnel ")]):_vm._e()],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"lg":"2"}},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"type","options":[
                {
                  type: 'Cash Advance',
                  id: 'cash_advance',
                },
                {
                  type: 'Charges',
                  id: 'charges',
                },
                {
                  type: 'SSS Loan',
                  id: 'sss_loan',
                },
                {
                  type: 'Pagibig Loan',
                  id: 'pagibig_loan',
                },
                {
                  type: 'Other Deductions',
                  id: 'other_deductions',
                } ],"reduce":function (item) { return item.id; },"placeholder":"Type"},model:{value:(_vm.entry_type),callback:function ($$v) {_vm.entry_type=$$v},expression:"entry_type"}})],1)]),_c('CCol',{attrs:{"lg":"2"}},[_c('div',{staticClass:"form-inline"},[_c('Datepicker',{attrs:{"input-class":"form-control bg-white mr-2","placeholder":"Start Date","disabled-dates":_vm.disabledDatesStart},on:{"selected":_vm.changeStartDate},model:{value:(_vm.date_start),callback:function ($$v) {_vm.date_start=$$v},expression:"date_start"}})],1)]),_c('CCol',{attrs:{"lg":"2"}},[_c('Datepicker',{attrs:{"input-class":"form-control bg-white","placeholder":"End Date","disabled-dates":_vm.disabledDatesEnd},on:{"selected":_vm.changeEndDate},model:{value:(_vm.date_end),callback:function ($$v) {_vm.date_end=$$v},expression:"date_end"}})],1),_c('CCol',{attrs:{"lg":"4"}},[_c('div',{staticStyle:{"text-align":"left"}},[(_vm.config.getPermission('personnel_ledger').download)?_c('CButton',{staticStyle:{"margin-right":"80px"},attrs:{"size":"sm","color":"btn btn-outline-primary"},on:{"click":_vm.download}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download ")]):_vm._e()],1)])],1),_c('hr'),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CDataTable',{attrs:{"tableFilter":{ placeholder: 'Search here...' },"pagination":"","itemsPerPage":10,"itemsPerPageSelect":"","loading":_vm.isLoading,"items":_vm.personnel_Data,"fields":_vm.fields,"hover":"","outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{staticStyle:{"margin-top":"10px"},attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"action",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(_vm.config.getPermission('personnel_ledger').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v("View")])]):_vm._e()],1)],1)]}}])})],1)])],1)],1),_c('CModal',{attrs:{"title":_vm.title,"show":_vm.formModal,"color":"info","size":"md"},on:{"update:show":function($event){_vm.formModal=$event},"close":function($event){return _vm.closeModal()}}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"font-size":"12px"}},[_vm._v("Crew (Personnel Name) "),(!_vm.is_disabled)?_c('span',{staticClass:"text-danger"},[_vm._v("* ")]):_vm._e()]),_c('v-select',{attrs:{"label":"name","options":_vm.personnelList.data,"reduce":function (item) { return item.id; },"placeholder":"Select.."},model:{value:(_vm.adjustmentParam.personnel_id),callback:function ($$v) {_vm.$set(_vm.adjustmentParam, "personnel_id", $$v)},expression:"adjustmentParam.personnel_id"}})],1)])],1),_c('div',{staticClass:"w-100 mt-3",attrs:{"slot":"footer"},slot:"footer"},[(!_vm.is_disabled)?[_c('CButton',{staticClass:"ml-1 mr-1 float-right",staticStyle:{"border-radius":"0.2rem","color":"white"},attrs:{"size":"sm","type":"submit","color":"info"}},[_vm._v(" Next ")])]:_vm._e(),_c('CButton',{staticClass:"ml-1 mr-1 float-right",staticStyle:{"border-radius":"0.2rem","color":"white"},attrs:{"size":"sm","color":"danger"},on:{"click":function($event){_vm.formModal = false}}},[_vm._v(" Close ")])],2)],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }